export default {
  methods: {
    getStyle(item) {
      let s1 = 'background:#4FBEBD;color: #FFFFFF;'
      let s2 = 'background:#E6425E;color: #FFFFFF;'
      let s3 = 'background:#EEEEEE;color: #666666;'

      // 暂关闭 已结束 报名结束
      if (item.state == 3 || item.status == 5 || item.status == 6) return s3
      // 去报名
      else if (item.status == 9) return s2
      // 未开始
      else if (item.status == 8) return s1
    },
    getText(item) {
      // 正常
      if (item.state == 2) {
        // 已报满
        if (item.state == 4) return '已报满'
        // 去报名
        else if (item.status == 9) return '去报名'
        // 未开始
        else if (item.status == 8) return '未开始'
        // 进行中
        else if (item.status == 7) return '进行中'
        // 报名结束
        else if (item.status == 6) return '报名结束'
        // 已结束
        else if (item.status == 5) return '已结束'
      }
      // 暂关闭
      else if (item.state == 3) return '暂关闭'
    },
    getGroudText(item) {
      // 去报名
      if (item.state == 1) return '去报名'
      // 暂关闭
      else if (item.state == 2) return '暂关闭'
      // 已报满
      else if (item.state == 3) return '已报满'
    },
    // 订单颜色
    orderTextStyle(item) {
      let s1 = 'color:#C59053'
      let s2 = 'color:#468EFF'
      let s3 = 'color:#6FBA2C'

      // 待支付
      if (item.show_state == 0) return s1
      // 待参与
      else if (item.show_state == 1) return s2
      // 已完成
      else if (item.show_state == 2) return s3
    },
    // 订单文字
    orderText(item) {
      // 待支付
      if (item.show_state == 0) return '待支付'
      // 待参与
      else if (item.show_state == 1) return '已报名'
      // 已完成
      else if (item.show_state == 2) return '已完成'
    }
  }
}
