<template>
  <div>
    <div class="content" v-if="info">
      <div class="basic flex_space_between">
        <div class="basic_left">
          <img :src="info.cover" />
        </div>
        <div class="basic_center flex_direction_column">
          <div class="basic_center_title">{{ info.name }}</div>
          <div class="xh flex_wrap">
            <div class="xh_item flex">
              <img src="@/assets/image/icon/03.png" />
              <span>{{ info.club_name }}</span>
            </div>
          </div>
          <div class="desc flex_direction_column">
            <p>报名时间：{{ info.sign_start }}-{{ info.sign_end }}</p>
            <p>活动时间：{{ info.start_time }}</p>
            <p>活动地址：{{ info.province }}·{{ info.city }}·{{ info.address }}</p>
          </div>
          <div class="price" v-if="info.group.price != '0.0'">¥{{ info.group.price }}</div>
          <div class="price" v-else>免费</div>
        </div>
        <div class="basic_right flex_direction_column">
          <!-- <div class="entry flex">
            <img src="@/assets/image/icon/08.png" />
            <span>手机报名</span>
          </div> -->
          <div class="basic_info_right_top flex">
            <img src="@/assets/image/icon/08.png" />
            <span>小程序报名</span>
            <div class="code">
              <img class="qr_code" :src="info.share" />
              <p>扫码报名</p>
            </div>
          </div>
          <div class="look flex">
            <div class="look_box flex">
              <img src="@/assets/image/icon/10.png" />
              <span>浏览{{ info.browses }}</span>
            </div>
            <div class="look_box flex">
              <img src="@/assets/image/icon/11.png" />
              <span>报名{{ info.enroll_num }}</span>
            </div>
          </div>
          <div class="btn" :style="getStyle(info)" @click="entryClick">
            {{ getText({ state: info.state, status: info.status }) }}
          </div>
        </div>
      </div>
      <RichDetail :info="info"></RichDetail>
      <orderPopup :isShow="isShowOrder" @confirm="confirm" @close="close"></orderPopup>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import statusMinxin from '@/minxin/status.js'
import { checkOrder } from '@/service/api/user'
import { activeDetail } from '@/service/api/active'
import RichDetail from '@/components/rich/detail.vue'
import orderPopup from '@/components/popup/order.vue'
export default {
  mixins: [statusMinxin],
  components: { RichDetail, orderPopup },
  data() {
    return {
      html: '',
      info: '',
      isOrderID: 0,
      cancel: false,
      isShowOrder: false
    }
  },

  mounted() {
    this.activeDetail(this.$route.query.id)
  },

  methods: {
    async checkOrder() {
      const res = await checkOrder({ id: this.$route.query.id })
      if (res.code == 1) {
        this.isShowOrder = true
        this.isOrderID = res.msg
      } else {
        this.$router.push({
          path: '/activity-entry',
          query: {
            id: this.info.id
          }
        })
      }
      console.log(res)
    },
    async activeDetail(id) {
      const res = await activeDetail({ id })
      this.info = res.msg
      console.log(this.info)
    },
    confirm() {
      this.$router.push({
        path: '/activity-entry',
        query: { orderId: this.isOrderID }
      })
    },
    close(val) {
      this.cancel = true
      this.isShowOrder = val
      this.$router.push({
        path: '/activity-entry',
        query: {
          id: this.info.id
        }
      })
    },
    entryClick() {
      if (this.getText({ state: this.info.state, status: this.info.status }) != '去报名') return
      sessionStorage.clear()
      if (!this.cancel) {
        this.checkOrder()
      } else {
        this.$router.push({
          path: '/activity-entry',
          query: {
            id: this.info.id
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.basic_info_right_top {
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
  &:hover {
    .code {
      height: 340px;
      opacity: 1;
      .qr_code {
        height: 250px;
      }
      p {
        height: 22px;
      }
    }
  }
  img {
    width: 14px;
    height: 14px;
    margin-bottom: 3px;
  }
  span {
    height: 22px;
    margin-left: 5px;
    line-height: 22px;
    font-size: @font_size_16;
  }
  .code {
    top: 15px;
    height: 0;
    right: 0px;
    opacity: 0;
    width: 305px;
    overflow: hidden;
    // height: 340px;
    transition: all 0.3s;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../assets/image/img/17.png');
    .qr_code {
      height: 0;
      width: 250px;
      margin: 35px auto 10px auto;
      border: 2px solid @color_five;
    }
    p {
      height: 0;
      width: 100%;

      display: block;
      text-align: center;
      font-size: @font_size_16;
    }
  }
}
.basic {
  align-items: flex-start;
  background-color: #fff;
  padding: 43px 40px 56px 40px;
  .basic_left {
    width: 368px;
    height: 247px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
  .basic_center {
    width: 570px;
    margin-left: 23px;
    .basic_center_title {
      line-height: 42px;
      font-size: @font_size_26;
    }
    .xh {
      margin-top: 8px;
      .xh_item {
        margin-right: 10px;
        margin-bottom: 10px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        span {
          height: 22px;
          line-height: 22px;
          color: @color_seven;
          font-size: @font_size_16;
        }
      }
    }
    .desc {
      height: 80px;
      margin-top: 19px;
      color: @color_seven;
      font-size: @font_size_16;
      p {
        line-height: 30px;
      }
    }
    .price {
      height: 33px;
      margin-top: 33px;
      line-height: 33px;
      color: @color_one;
      font-size: @font_size_24;
    }
  }
  .basic_right {
    flex: 1;
    height: 247px;
    position: relative;
    align-items: flex-end;
    // justify-content: flex-end;

    .entry {
      img {
        width: 14px;
        height: 14px;
      }
      span {
        height: 22px;
        margin-left: 5px;
        line-height: 22px;
        font-size: @font_size_16;
      }
    }
    .look {
      margin-top: 38px;
      .look_box {
        &:nth-child(1) {
          &::after {
            content: '';
            width: 1px;
            height: 10px;
            display: block;
            margin: 0 23px;
            background-color: #ddd;
          }
        }
        img {
          width: 24px;
          height: 24px;
        }
        span {
          height: 25px;
          margin-left: 5px;
          line-height: 25px;
          color: @color_seven;
          font-size: @font_size_16;
        }
      }
    }
    .btn {
      width: 144px;
      height: 46px;
      color: #fff;
      cursor: pointer;
      margin-top: 30px;
      line-height: 46px;
      border-radius: 5px;
      text-align: center;
      background-color: @color_one;
    }
  }
}
</style>
