import { service } from '../index'

// 活动分类数据
export function activeClassify() {
  return service.post({
    url: '/index/activeClassify'
  })
}

// 活动城市
export function activeCity(data) {
  return service.post({
    url: '/index/activeCity',
    data
  })
}

// 活动列表
export function activeList(data) {
  return service.post({
    url: '/index/activeList',
    data
  })
}

// 活动详情
export function activeDetail(data) {
  return service.post({
    url: '/index/activeDetail',
    data
  })
}

// 活动提交表单页面
export function activeOrder(data) {
  return service.post({
    url: '/order/activeOrder',
    data
  })
}

// 活动报名提交
export function addActiveOrder(data) {
  return service.post({
    url: '/order/addActiveOrder',
    data
  })
}
