<template>
  <div class="detail" v-if="info">
    <div class="vertical_title flex">{{ info.type != 3 ? '赛事详情' : '活动详情' }}</div>
    <div class="rich ql-snow ql-editor" v-html="info.detail"></div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.detail {
  margin-top: 20px;
  border-radius: 3px;
  padding: 43px 40px;
  margin-bottom: 54px;
  background-color: #fff;
}
.rich {
  margin-top: 45px;
}
</style>
