<template>
  <div>
    <el-dialog title="" top="0" center :show-close="false" :visible.sync="dialogVisible" width="739px" @close="close">
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/12.png" />
        <div class="desc flex_direction_column">
          <img class="pic" src="../../assets/image/img/45.png" alt="" />
          <span>您有订单未支付，是否去支付~</span>
        </div>
        <div class="btn flex_center">
          <div class="box" @click="dialogVisible = !dialogVisible">继续报名</div>
          <div class="box" @click="confirmClick">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  mounted() {},

  methods: {
    confirmClick() {
      this.dialogVisible = false
      this.$emit('confirm', this.orderId)
    },
    close() {
      this.$emit('close', false)
    }
  },
  watch: {
    isShow(val) {
      this.dialogVisible = val
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__title {
  height: 30px;
  line-height: 30px;
  color: @color_one;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_22;
}
.close {
  top: 21px;
  right: 17px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: absolute;
}
.desc {
  align-items: center;
  justify-content: center;
  margin-bottom: 58px;
  .pic {
    width: 167px;
    height: 93px;
  }
  span {
    height: 25px;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    margin-top: 38px;
    color: #000000;
  }
}
.dialog_content {
  width: 100%;
  max-height: 535px;
  border-radius: 5px;
  .btn {
    margin-top: 14px;
    font-size: 16px;
    .box {
      color: #fff;
      padding: 8px 0;
      cursor: pointer;
      min-width: 182px;
      border-radius: 5px;
      text-align: center;
      background-color: @color_one;
      &:nth-child(1) {
        color: #e6425e;
        margin-right: 40px;
        background-color: #f9f9f9;
      }
    }
  }
}
</style>
